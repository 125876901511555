import React, { useState, useEffect } from "react";
import "./Ticket.css";
import { Drawer } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { toast } from "react-toastify";
import { closeTicketApi, sendMessageApi, ticketMessageApi } from "../../api";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';


const AllTicket = ({ data }) => {
    const token = useSelector((state) => state?.user?.user?.token);
    const adminId = useSelector((state) => state?.user?.user?._id);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [textFromAdmin,setTextFromAdmin]=useState(false);

  const handleFetchMsg = async (ticketId) => {

    try {
      const response = await ticketMessageApi({ token, ticketId });
    //   console.log("Messages fetched:", response);
      setMessages(response?.data || []); 
    } catch (error) {
      console.error("Error fetching messages:", error);
       const errorMessage = error?.data?.errors;
            if (Array.isArray(errorMessage)) {
              errorMessage.forEach((msg) => toast.error(msg));
            } else if (errorMessage) {
              toast.error(errorMessage);
            }
          
    }
  };

  // console.log("sss",selectedTicket, selectedTicket?.user[0]?._id )
  const sendMessage = async (ticket, message) => {
    try {
        const payload = {
            ticket: selectedTicket?._id,
            message,
            to: selectedTicket?.user[0]?._id 
        };
        setTextFromAdmin(false)
        const response = await sendMessageApi({ token, payload });
            // console.log("d",response);
        if (response?.data) {
            setMessages((prevMessages) => [...prevMessages, response.data]);
            setTextFromAdmin(true)
        } else {
            toast.error("Failed to send the message");
        }
    } catch (error) {
        console.error("Error sending message:", error);
        const errorMessage = error?.data?.errors;
        if (Array.isArray(errorMessage)) {
            errorMessage.forEach((msg) => toast.error(msg));
        } else if (errorMessage) {
            toast.error(errorMessage);
        }
    }
};


  const closeTicket = async (ticketId) => {
    const conf=window.confirm("Are you sure you want to close this ticket ?")
    if(!conf){
        return
    }
   try {
            const res=await closeTicketApi({token,ticketId})
   } catch (error) {
    const errorMessage = error?.data?.errors;
    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((msg) => toast.error(msg));
    } else if (errorMessage) {
      toast.error(errorMessage);
    }

   }
  };


  const handleTicketClick = async (ticket) => {
    // console.log("f",ticket);
    handleFetchMsg(ticket?._id);
    setSelectedTicket(ticket);
    setIsDrawerOpen(true);
   

    

  };

  // Handle drawer close
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedTicket(null);
  };

  // Handle sending a message
  const handleSendMessage = () => {
    if (!inputValue.trim()) {
      toast.error("Message cannot be empty");
      return;
    }
    sendMessage(selectedTicket._id, inputValue);
    setInputValue("");
  };

  return (
    <div>
      {/* Tickets List */}
      {data && data.length > 0 ? (
        data.map((ticket) => (
          <div
            className="ticket-container"
            key={ticket._id}
            onClick={() => handleTicketClick(ticket)}
            style={{ cursor: "pointer" }}
          >

            <div className="ticket-title">
              <strong>Title:</strong> {ticket.title || "N/A"}
            </div>

      
            <div className="ticket-desc">
              <strong>Description:</strong>{" "}
              {ticket.description || "No description available"}
            </div>

            <div className="ticket-footer">
              <div className="ticket-status">
                <strong>Status:</strong> <span style={{color:ticket?.status==='new'? "#FFC107" :"#009048"}}>{ticket?.status || "N/A"}</span>
              </div>
              <div className="ticket-raiseby-user">
                <strong>Raised By:</strong>{" "}
                <span style={{fontWeight:'500'}}>
                {ticket.user[0]?.first_name
                  ? `${ticket.user[0]?.first_name} ${ticket.user[0]?.last_name}`
                  : "N/A"}
                </span>
                
              </div>
              <div className="ticket-created">
                <strong>Created At:</strong>{" "}
                {new Date(ticket.createdAt).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No tickets available</div>
      )}

      {/* Drawer for Selected Ticket */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: { borderRadius: "25px 0px 0px 25px", width: "340px" },
        }}
      >
      {selectedTicket && (
  <div
    style={{
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      height: "95vh",
    //   position: "relative",
    }}
  >
    {/* Close Icon */}
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
      }}
      onClick={handleDrawerClose}
    >
    <CloseIcon/>
    </div>

    {/* Title */}
    <h2 style={{ wordBreak: "break-word" }}>
      {selectedTicket.title
        ? selectedTicket.title.substring(0, 150) +
          (selectedTicket.title.length > 150 ? "..." : "")
        : "No Title"}
    </h2>

    {/* Description */}
    <p style={{ wordBreak: "break-word", marginBottom: "20px" }}>
      {selectedTicket.description
        ? selectedTicket.description.substring(0, 150) +
          (selectedTicket.description.length > 150 ? "..." : "")
        : "No Description"}
    </p>

    {/* Close Ticket */}
    <div style={{display:'flex',justifyContent:'end'}}>
    <div
      onClick={() => closeTicket(selectedTicket?._id)}
      style={{
        cursor: "pointer",
        color: "white",
        marginBottom: "20px",
        fontWeight: "600",
        background:'linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)',
        width:'120px',
       minHeight:'35px',
        borderRadius:'25px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
      }}
    >
      Close Ticket
    </div>
    </div>

    {/* Messages */}
    <div
  style={{
    overflowY: "scroll",
    marginTop: "20px",
    minHeight: "360px",
    backgroundColor: "#F6F6F6",
    padding: "10px",
    borderRadius: "10px",
    display: "flex", 
    flexDirection: "column", 
    alignItems: "flex-end",
  }}
>
  {messages?.length > 0 ? (
    messages.map((msg) => {
      const isAdmin = msg?.from_user?.[0]?.roles == "Admin" ||  msg?.from === adminId;
      return (
        <div
        key={msg._id}
        style={{
          background:
            isAdmin
              ? "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)"
              : "rgb(185 185 185)",
          color: isAdmin ? "#FFF" : "#000",
          padding: "10px",
          borderRadius:
            isAdmin
              ? "18px 18px 18px 0px"
              : "18px 18px 0px 18px",
          margin: "5px 0",
          textAlign: "left",
          maxWidth: "80%",
          alignSelf: isAdmin ? "flex-end" : "flex-start", 
        }}
      >
          {msg?.message || "No Message Available"}
        </div>
      );
    })
  ) : (
    <div
      style={{
        textAlign: "center",
        color: "#888",
        padding: "20px",
        fontStyle: "italic",
      }}
    >
      No messages available
    </div>
  )}
</div>



    {/* Input for Message */}
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Enter your message"
        style={{
          flex: 1,
          padding: "10px",
          marginRight: "10px",
          borderRadius: "4px",
          backgroundColor: "#F1F1F1",
          outline: "none",
          border: "none",
          height: "30px",
          borderRadius: "50px",
        }}
      />
      <div
        style={{
          background:
            "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",
          backgroundSize: "cover",
          height: "50px",
          width: "50px",
          borderRadius: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SendIcon
          onClick={handleSendMessage}
          style={{ cursor: "pointer", color: "#FFF" }}
        />
      </div>
    </div>
  </div>
)}

      </Drawer>
    </div>
  );
};

export default AllTicket;
