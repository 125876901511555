import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAllfeedbacksApi } from '../../api';
import { toast } from 'react-toastify';
import FeedBackRow from '../../common/tablerow/FeedBackRow';
import TableComp from "../../common/tablecomp/TableComp";
import TableCard from "../../common/tableCard/TableCard";
import Paginations from '../../common/pagintaion/Pagination';
import Loader from '../../common/loader/Loader'
import { TableCell, TableRow } from '@mui/material';

const FeedBack = () => {
    const token = useSelector((state) => state?.user?.user?.token);
    const [loading,setLoading]=useState(false);
    // const [currentPage,setCurrentPage]=useState(1)
    // const [totalPage,setTotalPage]=useState()
    const [allFeedbackData,setAllFeedbackData]=useState([])
  
   
  
  
  
    const handleFeedBackDetails=async()=>{
      try {
        setLoading(true)
        const response=await getAllfeedbacksApi({token})
        // console.log("fed",response);
        setAllFeedbackData(response?.data)
        // setCurrentPage(parseInt(response?.page))
        // setTotalPage(response?.pages)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        const errorMessage = error?.data?.errors;
        if (Array.isArray(errorMessage)) {
          errorMessage.forEach((msg) => toast.error(msg));
        } else if (errorMessage) {
          toast.error(errorMessage);
        }
      }
    }
    
  useEffect(()=>{
    handleFeedBackDetails()
  },[])
  
  
  const titles = [
    { title: "User Name" },
    { title: "Email" },
    { title: "Feedback" },
    { title: "Ratings" },
    { title: "Created Date" },
  ];
  
  // const handlePageChange = (page) => {
  //   setLoading(true);
  //   setCurrentPage(page);
  // };
  
    return (
      <>
      
      
      {loading ?(
        
        <Loader/>
      ):(
          
          <TableCard>
            <TableComp title={titles}>
            {allFeedbackData?.length >0 ? (
                  allFeedbackData?.map((item) => (
                    <FeedBackRow
                    row={item} 
                    key={item?._id}
                    />
                  ))
            ):(
                  <TableRow>
                    <TableCell colSpan={titles.length} align="center" sx={{fontFamily:'Rosario',fontWeight:"600"}}>
                      No Data Found
                    </TableCell>
                  </TableRow>
            )}
           
             {/* <Paginations
                onPageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPage}
                disabled={loading}
              /> */}
          </TableComp>
          </TableCard>
  
             )}
             
      </>
    )
  }

export default FeedBack