import React, { useState } from "react";
import { TableRow, TableCell, Box, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SpotSeekerRow = ({ row }) => {
  const fullName = 
  row.user && row.user[0]
    ? `${row.user[0].first_name || ""} ${row.user[0].last_name || ""}`.trim() || "-"
    : "-";  

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const statusColor = row?.status === "spotted" ? "#9A031E" : (row?.status === "active" ? "#009048" : "#000000");

  return (
    <>
      <TableRow>
        <TableCell>
          <Box 
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Avatar
              alt={row?.user[0]?.first_name}
              src={row?.user[0]?.profile_picture}
              sx={{ borderRadius: "13px" }}
            />
            <div style={{ fontFamily: "Inter, sans-serif", fontWeight: 500, fontSize: "17px" }}>
              {fullName}
            </div>
          </Box>
        </TableCell>

        <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>
          <div>{row?.user[0]?.email || "-"}</div>
        </TableCell>

        <TableCell sx={{ fontFamily: "Inter, sans-serif", fontWeight: 500, fontSize: "17px" }}>
          <div>{row?.car_type || "-"}</div>
        </TableCell>

        <TableCell sx={{ fontFamily: "Inter, sans-serif", fontWeight: 500, fontSize: "17px" }}>
          <div>{row?.parking_type || "-"}</div>
        </TableCell>

        <TableCell sx={{ fontFamily: "Inter, sans-serif", fontWeight: 500, fontSize: "17px" }}>
        <div>{row?.distance ? `${row.distance} m` : "-"}</div>
        </TableCell>

        <TableCell
          sx={{
            fontFamily: "Inter, sans-serif",
            fontWeight: 500,
            fontSize: "17px",
            color: statusColor,  
          }}
        >
          <div>{row?.status || "-"}</div>
        </TableCell>

        {/* New Fields */}
        <TableCell sx={{ fontFamily: "Inter, sans-serif", fontWeight: 500, fontSize: "17px" }}>
          <div>{row?.make || "-"}</div>
        </TableCell>

        <TableCell sx={{ fontFamily: "Inter, sans-serif", fontWeight: 500, fontSize: "17px" }}>
          <div>{row?.license_plate_number || "-"}</div>
        </TableCell>
        
        <TableCell sx={{ fontFamily: "Inter, sans-serif", fontWeight: 500, fontSize: "17px" }}>
          <div>{row?.model || "-"}</div>
        </TableCell>

        <TableCell sx={{ fontFamily: "Inter, sans-serif", fontWeight: 500, fontSize: "17px" }}>
          <div>{row?.color || "-"}</div>
        </TableCell>

       

        <TableCell sx={{ fontFamily: "Inter, sans-serif", fontWeight: 500, fontSize: "17px" }}>
          <div>{row?.updatedAt ? new Date(row?.updatedAt).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }) : "-"}</div>
        </TableCell>
      </TableRow>
    </>
  );
};



export default SpotSeekerRow;
