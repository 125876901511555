import { Axios } from "./axios.interceptor";

// ************************************************ login Api*****************************************************
export const loginApi = (user) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.post(
                "/login/admin",
                {
                    email: user.email,
                    password: user.password,

                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};





export const getAllUserApi=({token,currentPage})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/users?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const getAllVehicalApi=({token,currentPage})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/vehicles?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const getAllSpotsApi=({token,currentPage})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/spots?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const getAllfeedbacksApi=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/feedbacks`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const getSeekerApi=({token,currentPage})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/seekers?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const getTicketApi=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/tickets`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}






export const ticketMessageApi=({token,ticketId})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.post(
                `/admin/ticket/messages`,{ticket:ticketId},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const sendMessageApi=({token,payload})=>{
    // console.log("pa",payload);
    
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.post(
                `/admin/ticket/messages/add`,{
                    ...payload
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}



export const closeTicketApi=({token,ticketId})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.put(
                `/tickets`,{id:ticketId,status:"closed"},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}
