import React, { useEffect, useState } from 'react';
import { IconButton, Badge, Button, Menu, MenuItem, MenuList } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import NavBarImg from '../assest/navbarImg.png'
import ProfileIcon from '../assest/logoutIcon.svg'
import './Main.css';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/userSlice';
import { toast } from 'react-toastify';


const Navbar = ({ toggleSidebar }) => {

  const userData = useSelector((state) => state?.user?.user);
  // console.log("g",userData)
  const dispatch=useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showLogoutDropdown, setShowLogoutDropdown] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [allowView, setAllowView] = useState(false);
  const [user, setUser] = useState();
  const navigate=useNavigate();

  const toggleLogoutDropdown = () => {
    setShowLogoutDropdown(!showLogoutDropdown);
  };
  const toggleNotificationDropdown = () => {
    setShowNotification(!showNotification);
  };

  const closeNotification=()=>{
    setShowNotification(false)
  }


const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};


  return (
    <nav>
      {/* <img src={NavBarImg} className="c" alt="top banner" /> */}
      <div className="header">
        <div style={{ width: '100%', display: 'flex', alignContent: 'center', alignItems: 'center',height:'60px', }}>
          <IconButton onClick={toggleSidebar} className="hamburger-menu" sx={{ background: "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)",}}>
            <MenuOutlinedIcon sx={{ color: '#FFF',fontSize:'30px' }} />
          </IconButton>
          <div className="welcome-container">
            <div className="welcome-text-dashboard">Welcome, {userData?.first_name||"Admin"}</div>
            {/* <p className="home-user-account">Home-User-Account</p> */}
          </div>
        </div>
      
      </div>
    </nav>
  );
};

export default Navbar;