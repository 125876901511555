import React from "react";
import { TableRow, TableCell, Box, Avatar } from "@mui/material";

const VehiclesRow = ({ row }) => {
  // console.log("vg",row)
  const user = row?.user?.[0] || [];
  const fullName = `${user.first_name || ""} ${user.last_name || ""}`.trim() || "-";

  return (
    <TableRow>
      {/* User Name */}
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Avatar alt={fullName} src={row?.picture||null} sx={{ borderRadius: "13px" }} />
          <div
            style={{
              // color: "#1877F2",
              fontFamily: "Inter, sans-serif",
              // textDecoration: "underline",
              fontWeight: 500,
              fontSize: "17px",
            }}
          >
            {fullName}
          </div>
        </Box>
      </TableCell>

      {/* Email */}
      <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>{user?.email || "-"}</TableCell>

      {/* Phone Number */}
      <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>{user?.phone_number || "-"}</TableCell>

      {/* Picture */}
      {/* <TableCell>
        {row?.picture ? (
          <Avatar
            alt={row.make || "Vehicle Image"}
            src={row?.picture}
            sx={{ width: 50, height: 50 }}
          />
        ) : (
          "No Picture"
        )}
      </TableCell> */}

      {/* Model */}
      <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>{row?.model || "-"}</TableCell>

      {/* Color */}
      <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>{row?.color || "-"}</TableCell>

      {/* License Plate Number */}
      <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>{row?.license_plate_number || "-"}</TableCell>

      {/* Make */}
      <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>{row?.make || "-"}</TableCell>
    </TableRow>
  );
};

export default VehiclesRow;
