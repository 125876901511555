import React, { useEffect, useState } from 'react'
import UsersRow from '../../common/tablerow/UsersRow'
import { useSelector } from 'react-redux';
import { getAllSpotsApi, getAllUserApi } from '../../api';
import { toast } from 'react-toastify';
import { TableCell, TableRow } from '@mui/material';
import Loader from "../../common/loader/Loader";
import TableComp from "../../common/tablecomp/TableComp";
import TableCard from "../../common/tableCard/TableCard";
import Paginations from '../../common/pagintaion/Pagination';
import SpotRow from '../../common/tablerow/SpotRow';


const Spots = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [loading,setLoading]=useState(false);
  const [currentPage,setCurrentPage]=useState(1)
  const [totalPage,setTotalPage]=useState()
  const [allSpotData,setAllSpotData]=useState([])

 



  const handleSpotsDetails=async()=>{
    try {
      setLoading(true)
      const response=await getAllSpotsApi({token,currentPage})
      // console.log("spot",response);
      setAllSpotData(response?.data)
      setCurrentPage(parseInt(response?.page))
      setTotalPage(response?.pages)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }
  
useEffect(()=>{
  handleSpotsDetails()
},[currentPage])


  const titles = [
    { title: "User Name" },
    { title: "Email" },
    { title: "Car Type" },
    { title: "Parking Type" },
    { title: "Status" },
    { title: "Updated Date" },
  ];

const handlePageChange = (page) => {
  setLoading(true);
  setCurrentPage(page);
};

  return (
    <>
    
    
    {loading ?(
      
      <Loader/>
    ):(
        
        <TableCard>
          <TableComp title={titles}>
          {allSpotData?.length >0 ? (
                allSpotData?.map((item) => (
                  <SpotRow 
                  row={item} 
                  key={item?._id}
                  />
                ))
          ):(
                <TableRow>
                  <TableCell colSpan={titles.length} align="center" sx={{fontFamily:'Rosario',fontWeight:"600"}}>
                    No Data Found
                  </TableCell>
                </TableRow>
          )}
         
           <Paginations
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPage}
              disabled={loading}
            />
        </TableComp>
        </TableCard>

           )}
           
    </>
  )
}

export default Spots