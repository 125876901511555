import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import AllTicket from "./AllTicket";
import ResolvedTicket from "./ResolvedTicket";
import UnResolvedTicket from "./UnResolvedTicket";
import { getTicketApi } from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Loader from "../../common/loader/Loader";

const SupportTicket = () => {
  const [filter, setFilter] = useState("all");
  const token = useSelector((state) => state?.user?.user?.token);
  const [ticketData,setTicketData]=useState([])
  const [loading,setLoading]=useState(false)

  const handleButtonClick = (status) => {
    setFilter(status);
  };


  const handleTicketData=async()=>{
      try {
        setLoading(true)
        const response=await getTicketApi({token})
        // console.log("ticket",response)
        setTicketData(response?.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log("err",error)
         const errorMessage = error?.data?.errors;
              if (Array.isArray(errorMessage)) {
                errorMessage.forEach((msg) => toast.error(msg));
              } else if (errorMessage) {
                toast.error(errorMessage);
              }
            }
      }
  

  useEffect(()=>{
    handleTicketData()
  },[])

  
  return (
    <>
    {loading && <Loader/>}
    <div
      style={{
        height: "100vh",
        padding: "20px",
      }}
    >
      {/* Title */}
      {/* <h2 style={{ textAlign: "center", marginBottom: "30px", color: "#fff" }}>
        Support Tickets
      </h2> */}

      {/* Buttons for filtering tickets */}
      <Box display="flex" justifyContent="center" gap="20px">
        <Button
          variant={filter === "all" ? "contained" : "outlined"}
          sx={{
            width: "150px",
            borderRadius: "25px",
            height: "50px",
            textTransform: "none",
            background: filter === "all" ? "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)" : "transparent",
            color: filter === "all" ? "white" : "black",
            border: filter === "all" ? "none" : "1px solid black",
             fontFamily: 'Inter, sans-serif'
          }}
          onClick={() => handleButtonClick("all")}
        >
          All
        </Button>
        <Button
          variant={filter === "resolved" ? "contained" : "outlined"}
          sx={{
            width: "150px",
            borderRadius: "25px",
            height: "50px",
            textTransform: "none",
            background: filter === "resolved" ? "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)" : "transparent",
            color: filter === "resolved" ? "white" : "black",
            border: filter === "resolved" ? "none" : "1px solid black",
             fontFamily: 'Inter, sans-serif'
          }}
          onClick={() => handleButtonClick("resolved")}
        >
          Resolved
        </Button>
        <Button
          variant={filter === "unresolved" ? "contained" : "outlined"}
          sx={{
            width: "150px",
            borderRadius: "25px",
            height: "50px",
            textTransform: "none",
            background: filter === "unresolved" ? "linear-gradient(90deg, #4449FE 17.88%, #13A6F2 100%)" : "transparent",
            color: filter === "unresolved" ? "white" : "black",
            border: filter === "unresolved" ? "none" : "1px solid black",
            fontFamily: 'Inter, sans-serif'
          }}
          onClick={() => handleButtonClick("unresolved")}
        >
          Unresolved
        </Button>
      </Box>

      {/* Conditional Rendering Based on Selected Filter */}
      {/* <div
        style={{
          marginTop: "30px",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        }}
      > */}
        {filter === "all" && <AllTicket data={ticketData}/>}
        {filter === "resolved" && <ResolvedTicket />}
        {filter === "unresolved" && <UnResolvedTicket />}
      {/* </div> */}
    </div>
    </>
  );
};

export default SupportTicket;
