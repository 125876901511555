import React from "react";
import { TableRow, TableCell, Box, Avatar } from "@mui/material";

const FeedBackRow = ({ row }) => {
  const user = row?.user?.[0] || [];
  const fullName = `${user?.first_name || ""} ${user?.last_name || ""}`.trim() || "-";
// console.log("feddd",row)
  return (
    <TableRow>
      {/* User Name */}
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Avatar alt={fullName} src={row?.picture||null} sx={{ borderRadius: "13px" }} />
          <div
            style={{
            //   color: "#1877F2",
              fontFamily: "Inter, sans-serif",
            //   textDecoration: "underline",
              fontWeight: 500,
              fontSize: "17px",
            }}
          >
            {fullName}
          </div>
        </Box>
      </TableCell>

      {/* Email */}
      <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>{user?.email || "-"}</TableCell>

   
      <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>{row?.feedback || "-"}</TableCell>

      {/* Picture */}
      {/* <TableCell>
        {row?.picture ? (
          <Avatar
            alt={row.make || "Vehicle Image"}
            src={row?.picture}
            sx={{ width: 50, height: 50 }}
          />
        ) : (
          "No Picture"
        )}
      </TableCell> */}

 
      <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>
      <div>{row?.rating ?  parseFloat(row?.rating).toFixed(2):0}</div>
        
        </TableCell>

      <TableCell sx={{ fontFamily: "Inter, sans-serif", fontSize: "17px" }}>
        
      <div>{
              row?.createdAt ? 
              new Date(row?.createdAt || "-").toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
              :"-"
              }</div>

      </TableCell>

    </TableRow>
  );
};

export default FeedBackRow;
